import emailjs from "@emailjs/browser";
emailjs.init({
  publicKey: "54Bgus4KdGYmNUCw8",
});
export const sendNewAdvertiserAlert = ({
  company,
  userName,
  userPhone,
  userEmail,
}) => {
  try {
    return emailjs.send("service_fapk7q4", "template_d1ygpbj", {
      company,
      userName,
      userPhone,
      userEmail,
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendNewPublisherAlert = ({
  platform,
  userName,
  userPhone,
  userEmail,
}) => {
  try {
    return emailjs.send("service_fapk7q4", "template_g6u2m2z", {
      platform,
      userName,
      userPhone,
      userEmail,
    });
  } catch (error) {
    console.log(error);
  }
};
