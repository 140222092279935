const Spinner = ({ borderColor }) => {
  return (
    <div
      className={`border-4 border-t-transparent ${
        borderColor && "border-white"
      } rounded-full animate-spin size-6`}
    ></div>
  );
};

export default Spinner;
