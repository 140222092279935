const PrivacyPolicy = () => {
  return (
    <div className="bg-white w-full min-h-screen pt-24 text-textColor">
      <div className="w-10/12 md:w-10/12 mx-auto space-y-3">
        <div className="p-6 ">
          <div className=" mx-auto">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <p className="text-sm text-gray-600 mb-8">
              Last updated: September 20, 2024
            </p>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
              <p className="mb-4">
                NOTICE: Please read this notice carefully as it contains
                important information about how we collect and process personal
                information about you.
              </p>
              <p className="mb-4">
                Welcome to Kwanza Privacy Policy (“Policy”). In this Policy, we
                use the terms “Kwanza“, “our“, “us” and “we” to refer to Kwanza
                Media Trading Desk, and our affiliates. Kwanza is committed to
                protecting the privacy of individuals (“users”, “you“, “your“)
                when we process data about them when they visit our website at
                <a href="https://www.kwanza.co.tz" className="text-blue-500">
                  {" "}
                  www.kwanza.co.tz
                </a>{" "}
                (the "Website") or our content creators platforms and when we
                engage in marketing activities based on contact information they
                provide to Kwanza through other means described in this Policy.
              </p>
              <p className="mb-4">
                This Policy sets out how Kwanza collects and processes personal
                data about you via the Platform, when you visit the Website, and
                when we engage in marketing activities based on contact
                information you provide to Kwanza through other means described
                in this Policy. It also sets out our practices regarding our use
                of such data, the steps we take to protect it, the parties with
                whom we share it, and the choices and rights that you have
                regarding how we collect and process personal data about you.
              </p>
              <p className="mb-4">
                You should read this Policy carefully so that you are fully
                aware of how and why we are using your personal data.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">About Us</h2>
              <p className="mb-4">
                Kwanza is the media trading desk platform narrowing the gap
                between publishers and advertisers by bringing advertisers to
                publishers, media houses, and content creators. As a Demand-Side
                Platform, Supply-Side Platform, Monitoring platform, and our
                clients, which include advertisers and marketing agencies, use
                our technology to purchase advertising, including display,
                video, native, and mobile ads, radio, TV Commercial, Out Of Home
                in an automated fashion.
              </p>
              <p className="mb-4">
                Kwanza complies with the DAA “Self-Regulatory Principles for
                Online Behavioral Advertising”, their “Application of
                Self-Regulatory Principles to the Mobile Environment, and the
                “2020 NAI Code of Conduct”. We believe that these programs’
                principles help protect user privacy.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">How to contact us</h2>
              <p className="mb-4">
                If you have any questions about this Policy, including any
                request to exercise your legal rights, please contact us via
                email at
                <a href="mailto:info@kwanza.co.tz" className="text-blue-500">
                  {" "}
                  info@kwanza.co.tz
                </a>
                .
              </p>
              <p className="mb-4">
                You have the right to lodge any complaints you have regarding
                our processing of your personal data with a regulatory
                authority. We care about your privacy, and we appreciate the
                chance to address your concerns before you do so.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">
                Changes to the Policy
              </h2>
              <p className="mb-4">
                From time to time Kwanza may revise this Policy. The date of the
                last update can be found at the top of the Policy. If we make
                revisions that materially change or affect whether or how we
                collect or use personal data, we will notify you by email (if,
                as a user of our Website or recipient of our marketing
                communications, you have provided us your email address) or by
                means of a notice on our Website prior to the change becoming
                effective. If you would like to stay up-to-date on our most
                current policies and practices, you should review the Website
                periodically.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">
                Data pertaining to children
              </h2>
              <p className="mb-4">
                Kwanza products and services are not designed for children, and
                we do not knowingly collect personal data from or about any
                children. If we are made aware that we have received personal
                data from or about a child, we will use reasonable efforts to
                remove that data from our records.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">
                Links, other websites and other third parties
              </h2>
              <p className="mb-4">
                This Policy relates solely to the personal data we collect and
                process about you in relation to our Platform, our Website, and
                other interactions you may have with Kwanza described in this
                Policy. Kwanza’s Website contains links to other sites, and the
                ads delivered by the Platform may run on a variety of other
                sites. Clicking on those links, accessing other sites, or
                enabling those connections may allow third parties to collect or
                share data about you. Kwanza does not control these third-party
                sites and is not responsible for the privacy practices of such
                other sites. You should refer to the privacy statements of such
                third-party websites to find out how they collect and use your
                personal data and whether and for what purpose they use cookies.
                Also, as explained in this Policy third parties may process
                personal data from you when you visit the Website. Such
                processing is governed by privacy statements of such third
                parties.
              </p>
            </section>
            <div className=" mx-auto ">
              <h1 className="text-2xl font-bold mb-4">How We Use Your Data</h1>
              <p className="mb-4">
                We collect personal data to deliver relevant ads, improve our
                products and services, and create new offerings. Here are
                specific ways we use your data:
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>Enabling Kwanza clients to create marketing campaigns</li>
                <li>Collecting information about a browser or device</li>
                <li>Creating user segments</li>
                <li>Managing cookies and mobile identifiers</li>
                <li>Deciding on ad placements and bids</li>
                <li>Delivering ads based on your interests</li>
                <li>Measuring ad effectiveness</li>
                <li>Facilitating data collection for partners</li>
                <li>Honoring user opt-out requests</li>
                <li>Associating multiple browsers and devices</li>
                <li>Managing identity mapping across devices</li>
              </ul>

              <h2 className="text-xl font-semibold mb-2">
                Creation of Segments
              </h2>
              <p className="mb-4">
                Segments group users by shared attributes. Clients may use
                segments related to health interests. We do not create sensitive
                segments per EEA regulations.
              </p>

              <h2 className="text-xl font-semibold mb-2">
                Automated Decision-Making
              </h2>
              <p className="mb-4">
                Kwanza uses algorithms to decide on ad bids. The ads you see are
                based on client campaigns and our algorithms. These processes
                may involve profiling but do not affect your legal rights.
              </p>

              <h2 className="text-xl font-semibold mb-2">
                Legal Basis for Processing
              </h2>
              <p className="mb-4">
                We collect personal data based on legitimate interests,
                typically to operate the platform and serve ads.
              </p>

              <h2 className="text-xl font-semibold mb-2">Opt-Out Mechanisms</h2>
              <p className="mb-4">
                You can limit data collection and opt out of interest-based ads.
                If you opt out, you’ll see ads that are less relevant to you.
              </p>

              <h2 className="text-xl font-semibold mb-2">Disclosure of Data</h2>
              <p className="mb-4">We may share your data with:</p>
              <ul className="list-disc list-inside mb-4">
                <li>Clients and partners for operations</li>
                <li>Publishers and ad exchanges</li>
                <li>Third-party service providers</li>
                <li>To protect legal rights and comply with laws</li>
              </ul>

              <h2 className="text-xl font-semibold mb-2">Data Security</h2>
              <p className="mb-4">
                We implement measures to protect your data, but no transmission
                is guaranteed secure. We address data breaches as required by
                law.
              </p>

              <h2 className="text-xl font-semibold mb-2">Data Retention</h2>
              <p className="mb-4">
                We retain personal data based on processing purposes, typically
                for up to 24 months for IDs and 13 months for other data.
              </p>

              <h2 className="text-xl font-semibold mb-2">Your Legal Rights</h2>
              <p className="mb-4">
                You have rights regarding your personal data, including access,
                correction, deletion, and opting out of processing. Contact us
                to exercise these rights.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
